import React from "react";
import Layout from "../../components/Layout/Layout";
import Brochure from "../../components/Markup/Brochure";
import SEO from "../../components/seo";

import copy from "../../../locales/spa/brochure.json";
import { graphql, useStaticQuery } from "gatsby";
const Spanish = () => {
  const data = useStaticQuery(graphql`
    query {
      brochure: file(relativePath: { eq: "spa/Brochure.pdf" }) {
        publicURL
      }
      preview: file(relativePath: { eq: "spa/brochure-preview.png" }) {
        ...DesktopImage
      }
    }
  `);
  return (
    <Layout>
      <SEO name="resources" />
      <Brochure
        language="Spanish"
        copy={copy}
        previewImage={data.preview}
        brochureURL={data.brochure.publicURL}
        languageCode="spa"
      />
    </Layout>
  );
};

export default Spanish;
